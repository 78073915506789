import { Stack, Typography } from "@mui/material"
import React from "react"
import Button from "@mui/material/Button"
import { StaticImage } from "gatsby-plugin-image"
import { useTheme } from "@mui/system"
import Grid from "@mui/material/Grid"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import scrollTo from "gatsby-plugin-smoothscroll"
import ContactForm from "../contact-form"
import SwiperCore, { Pagination, Navigation, EffectFade } from "swiper"
// swiper bundle styles
import "swiper/swiper-bundle.min.css"

// swiper core styles
import "swiper/swiper.min.css"

// modules styles
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"
import "./style.css"


SwiperCore.use([Pagination, Navigation, EffectFade])

const Contact = ({
  id,
  title,
  bgColor,
  showButton,
  showContactForm,
  showSlider,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Grid
      id={id}
      container
      sx={{
        display: "flex",
        width: "100%",
        backgroundColor: `${bgColor}`,
        padding: isMobile ? "20px 20px" : "20px 20%",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 500,
        boxShadow: "0px -3px 5px rgba(189, 189, 189, 0.8)"
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingRight: isMobile? "0px" : "50px"
        }}
      >
        <Typography
          component="h1"
          sx={{
            fontSize: isMobile ? "36px" : "42px",
            lineHeight: "48px",
            color: "#343434",
            padding: "10px 0",
            fontWeight: "bold",
            width: "100%",

            "&:after": {
              content: "''",
              margin: isMobile ? "10px 0" : "20px 0",
              display: "block",
              width: "15%",
              height: "3px",
              backgroundColor: `${theme.palette.secondary.main}`,
            },
          }}
        >
          {title}
        </Typography>
        {/* <Typography component="h1" sx={{fontSize: "32px", padding: "10px 0"}}>Zapraszamy do kontaktu.</Typography> */}
        <Stack direction="column" justifyContent="space-between" >
            <Stack direction="column" alignItems={isMobile ? "center" : "flex-start"} sx={{padding: isMobile ? "0" : "10px 0"}}>
                <p style={{fontWeight: "700", padding: "5px 0"}}>Geologia &amp; Geoinżynieria Wiercenia</p>
                <p style={{ padding: "5px 0"}}>Cezary Wojciechowski</p>
                <p style={{ padding: "5px 0"}}>ul. Podwisłocze 31/86</p>
                <p style={{ padding: "5px 0"}}>35-309 Rzeszów</p>
                <p style={{ padding: "5px 0"}}><span style={{fontWeight: "700"}}>NIP:</span> 8133623891</p>
                <p style={{ padding: "5px 0"}}><span style={{fontWeight: "700", padding: "3px 0"}}>REGON:</span> 520868701</p>
            </Stack>
         
        </Stack>
        <p style={{ margin: "15px 0", textAlign: isMobile ? "center" : "left" }}>
          <span style={{fontWeight: "700"}}>tel: </span><a href="tel:668-043-200">(+48) 668 043 200</a>
        </p>
        <p style={{textAlign: isMobile ? "center" : "left", marginBottom: isMobile ? "10px" : "0"}}>
        <span style={{fontWeight: "700"}}>email: </span> 
        <a data-auto-recognition="true" href="mailto:ctwojciechowski@gmail.com">ggwiercenia@gmail.com</a>
        </p>
   
    
        {showButton && (
          <Button
            color="secondary"
            variant="contained"
            sx={{
              margin: "30px auto",
              padding: "10px 25px",
              borderRadius: "8px",
            }}
            onClick={() => scrollTo(`#offer`)}
          >
            Oferta
          </Button>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {showContactForm ? (
          <ContactForm />
        ) : (
          <StaticImage
            src="../../assets/images/about.jpg"
            alt=""
            placeholder="blurred"
            layout="constrained"
            width={300}
          />
        )}
      </Grid>
     
    </Grid>
  )
}

export default Contact


