import { Typography } from "@mui/material"
import React from "react"
import Button from "@mui/material/Button"
import { StaticImage } from "gatsby-plugin-image"
import { useTheme } from "@mui/system"
import Grid from "@mui/material/Grid"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import scrollTo from "gatsby-plugin-smoothscroll"
import SwiperCore, { Pagination, Navigation, EffectFade } from "swiper"
// swiper bundle styles
import "swiper/swiper-bundle.min.css"

// swiper core styles
import "swiper/swiper.min.css"

// modules styles
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"
import "./style.css"

SwiperCore.use([Pagination, Navigation, EffectFade])

const About = ({
  id,
  title,
  bgColor,
  showButton,
  showContactForm,
  showSlider,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Grid
      id={id}
      container
      sx={{
        display: "flex",
        width: "100%",
        backgroundColor: `${bgColor}`,
        padding: isMobile ? "20px 20px" : "20px 20%",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 500,
        boxShadow: "inset 0px -3px 5px rgba(189, 189, 189, 0.8)"
      }}
    >

      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        order={{ xs: 1, md: 1 }}
      >
        <Typography
          component="h1"
          sx={{
            fontSize: isMobile ? "36px" : "42px",
            lineHeight: "48px",
            color: "#343434",
            padding: "10px 0",
            fontWeight: "bold",
            width: "100%",

            "&:after": {
              content: "''",
              margin: isMobile ? "10px 0" : "20px 0",
              display: "block",
              width: "15%",
              height: "3px",
              backgroundColor: `${theme.palette.secondary.main}`,
            },
          }}
        >
          {title}
        </Typography>
        <Typography sx={{ marginBottom: "10px", fontFamily: "Montserrat-Regular", textAlign: isMobile? "unset" : "justify"}}>
        <strong>G&G Wiercenia</strong> – Jesteśmy nowo powstałą firmą na rynku usług  geologicznych, jednak dzięki doświadczeniu zdobytemu przez wiele lat pracy przy obsłudze geologicznej  największych inwestycji  infrastrukturalnych w Polsce, świadczymy usługi na najwyższym poziomie. Mamy doświadczenie, bardzo dobry sprzęt i zespół ludzi, który jest przygotowany do pracy w każdych warunkach.
        </Typography>
        <Typography sx={{fontFamily: "Montserrat-Regular", textAlign: isMobile? "unset" : "justify"}}>
        <strong>G&G Wiercenia</strong> wraz z partnerami jest w stanie wykonać dla Was wszystkie prace, które są niezbędne do prawidłowego rozpoznania podłoża gruntowego oraz skalnego, w efekcie czego sporządzana jest odpowiednia dokumentacja geologiczna. Dokumentacja ta jest niezbędna dla prawidłowego wykonania projektu budowlanego oraz bezpiecznej realizacji każdej inwestycji. Nasze wieloletnie doświadczenie w tej branży pozwala nam na dużą mobilizację i szybkie dostosowanie się do wymagań rynkowych stawianych przy realizacji tego typu prac.
        </Typography>
        {showButton && (
          <Button
            color="secondary"
            variant="contained"
            sx={{
              margin: "30px auto",
              padding: "10px 25px",
              borderRadius: "8px",
            }}
            onClick={() => scrollTo(`#offer`)}
          >
            Oferta
          </Button>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: isMobile? "center" : "flex-end",
          alignItems: "center",
          margin: isMobile ? "10px auto": "unset",
        }}
        order={{ xs: 2, md: 2}}
      >
    
          <StaticImage
            src="../../assets/images/ggwiercenia_about.jpg"
            alt="G&G Wiercenia O firmie"
            placeholder="blurred"
            layout="constrained"
            width={400}
            height={400}
            style={{borderTopRightRadius: "20px", borderBottomRightRadius : "20px"}}
          />
        
      </Grid>
     
    </Grid>
  )
}

export default About

