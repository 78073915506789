import React, { useState } from "react"
import axios from "axios"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Grid, Button, Typography } from "@mui/material"
import * as yup from "yup"
import SubComponent1 from "../forms/subComponent1"
import SubComponent2 from "../forms/subComponent2"

const schema = yup.object().shape({
  email: yup.string().email('Nieporawny adres email.').required('Adres email jest wymagany.'),
  firstName: yup.string().required('Imię jest wymagane.'),
  title: yup.string().required('Tytuł jest wymagany.'),
  description: yup.string().required('Treść wiadomości jest wymagana.'),
})
const ContactForm = () => {
  const [sendingError, setSendingError] = useState(false)
  const [sendingDone, setSendingDone] = useState(false)
  //   const {
  //     register,
  //     handleSubmit,
  //     control,
  //     watch,
  //     formState: { errors },
  //   } = useForm({
  //     resolver: yupResolver(schema),
  //   })
  const methods = useForm({
    resolver: yupResolver(schema),
  })

  const formSubmitHandler = data => {
    setSendingError(false)
    let formData = new FormData()

    formData.append("email", data.email)
    formData.append("firstName", data.firstName)
    formData.append("title", data.title)
    formData.append("description", data.description)

    axios({
      method: "post",
      url: "https://www.ggwiercenia.pl/send.php",
      headers: { "content-type": "application/json" },
      data: formData,
    })
      .then(result => {
        result.status === 200 && methods.reset()
        setSendingDone(true);
        setTimeout(() => {
          setSendingDone(false);
        }, 10000)
      })
      .catch(error => setSendingError(true))

    // fetch("https://www.ggwiercenia.pl/send.php", {
    //   method: "POST",
    //   body: formData,
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    // })
    //   .then(response => {
    //     response.json().then(resData => {
    //       console.log("Successful" + resData)
    //     })
    //   })
    //   .catch(err => console.log("Error: ", err))
  }

  return (
    <Grid container direction="column" sx={{ width: "90%" }}>
      <FormProvider {...methods}>
        {/* <form onSubmit={methods.handleSubmit(formSubmitHandler)}> */}
        <form onSubmit={methods.handleSubmit(formSubmitHandler)}>
          {sendingError && (
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "16px",
                backgroundColor: "#e45f5f",
                padding: "10px",
                fontFamily: "Montserrat",
                color: '#fff'
              }}
            >
              Nie udało się wysłać wiadomości.
            </Typography>
          )}

          {sendingDone && <Typography
              sx={{
                textAlign: "center",
                fontSize: "16px",
                backgroundColor: "#0B523C",
                padding: "10px",
                fontFamily: "Montserrat",
                color: '#fff'
              }}
            >
              Wiadomość została wysłana.
            </Typography> }
          <SubComponent1 />
          <SubComponent2 />

          <Button variant="contained" color="secondary" type="submit" fullWidth>
            Wyślij
          </Button>
        </form>
      </FormProvider>
    </Grid>
  )
}

export default ContactForm
