import * as React from "react"
import "normalize.css"
import Layout from "../layout/layout.tsx"
import Seo from "../components/seo"
import HeroImage from "../components/hero-image"
import About from "../components/about"
import Offer from "../components/offer"
import Contact from "../components/contact"

const IndexPage = () => (
  <Layout>
    <Seo title="G&G Wiercenia | Usługi Geologiczne | Usługi Wiertnicze | Rzeszów | Podkarpacie" 
      description="Oferujemy profesjonalne usługi w zakresie geologii, geotechniki oraz geologii-inżynierskiej, przede wszystkim dla potrzeb budownicta mieszkaniowego, przemysłowego, kubaturowego oraz inwestycji liniowych. Wykorzystujemy najnowocześniejszy sprzęt ofertowany na rynku."
    />

    <HeroImage />

    <About
      id="about"
      title="Informacje o firmie"
      bgColor="rgba(189, 189, 189, 0.432)"
   
    />

    <Offer id="offer" title="Nasza oferta" bgColor="#FFFFFF"  />
    <Contact id="contact" title="Kontakt" bgColor="rgba(189, 189, 189, 0.432)" showContactForm />
  </Layout>
)

export default IndexPage
