import React from "react"
import { useFormContext, Controller } from "react-hook-form"
import TextField from "@mui/material/TextField"

const SubComponent2 = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  return (
    <>
      <Controller
        name="firstName"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            label="Imię"
            variant="outlined"
            fullWidth
            sx={{ margin: "10px 0", '.MuiInputLabel-root': {
              color: '#0B523C'
            }}}
          />
        )}
      />
      <Controller
        name="title"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            label="Temat"
            variant="outlined"
            fullWidth
            sx={{ margin: "10px 0", '.MuiInputLabel-root': {
              color: '#0B523C'
            }}}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            height={300}
            label="Treść"
            variant="outlined"
            fullWidth
            sx={{ margin: "10px 0", '.MuiInputLabel-root': {
              color: '#0B523C'
            }}}
            multiline
            minRows={3}
            maxRows={10}
          />
        )}
      />
    </>
  )
}

export default SubComponent2
