import React, { useEffect } from "react"
import Box from "@mui/material/Box"
import ground from "./rocks-3.jpg"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@mui/system"
import Logobialy from '../../assets/images/logo_bialy_pion.svg';
import Aos from 'aos';


const HeroImage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  useEffect(() => {
    Aos.init({
      duration: 2000
    })
  }, [])

  return (
    <Box
      sx={{
        postition: "relative",
        width: "100%",
        height: 550,
        backgroundImage: `url('${ground}')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: isMobile ? 'scroll': 'fixed'
      } }
    >
      <Box
         data-aos="flip-right"
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          width: isMobile ? "100%" : "50%",
          height: 550,
          backgroundColor: `rgba(52, 52, 52, 0.6)`,
          color: "#fff",
        }}
      >
        <Box sx={{ width: isMobile ? "90%" : "75%" }}>
        <Logobialy style={{ width: isMobile ? '100%' : '500px', height: "250px", marginBottom: '20px',  fontFamily: "Montserrat"}}/>
     
        </Box>
        <Box sx={{ fontSize: isMobile ? 30 : 40 }}>tel. 668 043 200</Box>
      </Box>
    </Box>
  )
}

export default HeroImage
