import React from "react"
import { useFormContext, Controller } from "react-hook-form"
import TextField from "@mui/material/TextField"

const SubComponent1 = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  return (
    <>
      <Controller
        name="email"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            type="email"
            label="Email"
            variant="outlined"
            error={!!errors.email}
            helperText={errors.email ? errors.email?.message : ""}
            fullWidth
            sx={{ margin: "10px 0", '.MuiInputLabel-root': {
              color: '#0B523C'
            }}}
          />
        )}
      />
      {/* <Controller
        name="password"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            type="password"
            label="Password"
            variant="outlined"
            error={!!errors.password}
            helperText={errors.password ? errors.password?.message : ""}
            fullWidth
          />
        )}
      /> */}
    </>
  )
}

export default SubComponent1
