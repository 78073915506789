import { ListItemIcon, ListItemText, Typography } from "@mui/material"
import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useTheme } from "@mui/system"
import Grid from "@mui/material/Grid"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import SwiperCore, { Pagination, Navigation, EffectFade } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import Aos from 'aos';
import "aos/dist/aos.css";
import "swiper/swiper-bundle.min.css"
// swiper core styles
import "swiper/swiper.min.css"
// modules styles
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"
import "./style.css"
import styled from 'styled-components'
SwiperCore.use([Pagination, Navigation, EffectFade])

const CustomListItem = styled.p`
  font-size: 0.875rem;
line-height: 1.43;
letter-spacing: 0.01071em;
font-family: "Montserrat-Regular";
`

const Offer = ({
  id,
  title,
  bgColor,
  showButton,
  showContactForm,
  showSlider,
}) => {
  useEffect(() => {
    Aos.init({
      duration: 2000
    })
  }, [])
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <>
    <Grid
      id={id}
      container
      sx={{
        display: "flex",
        width: "100%",
        backgroundColor: `${bgColor}`,
        padding: isMobile ? "20px 20px" : "20px 20%",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 500,
     
      }}
    >

    
       <Grid
        data-aos="fade-left"
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: isMobile ? "center" : "flex-start",
          alignItems: "center",
          margin: '10px auto',
        }}
        order={{ xs: 2, md: 1 }}  
      >
       
          <StaticImage
            src="../../assets/images/ggwiercenia_oferta.jpg"
            alt="G&G Wiercenia Oferta"
            placeholder="blurred"
            layout="constrained"
            objectFit="contain"
            width={400}
            height={500}
            style={ !isMobile ? { borderTopLeftRadius:'20px', borderBottomLeftRadius: '20px'} : {borderRadius: '20px'}}
          />
        
      </Grid>
      <Grid
       data-aos="fade-left"
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent:isMobile ? "center" : "flex-end",
          alignItems: "center",
          margin: '10px auto'
        }}
        order={{ xs: 1, md: 2 }}
      >
      
        <Typography
          component="h1"
          sx={{
            fontSize: isMobile ? "36px" : "42px",
            lineHeight: "48px",
            color: "#343434",
            padding: "10px 0",
            fontWeight: "bold",
            width: "100%",

            "&:after": {
              content: "''",
              margin: isMobile ? "10px 0" : "20px 0",
              display: "block",
              width: "15%",
              height: "3px",
              backgroundColor: `${theme.palette.secondary.main}`,
            },
          }}
        >
          {title}
        </Typography>
        <Typography sx={{ marginBottom: "10px",fontFamily: "Montserrat-Regular", textAlign: isMobile? "unset" : "justify" }}>
        G&G Wiercenia – oferuje profesjonalne usługi w zakresie geologii, geotechniki oraz geologii-inżynierskiej, przede wszystkim dla potrzeb budownictwa mieszkaniowego, przemysłowego, kubaturowego oraz inwestycji liniowych. Jesteśmy firmą wykorzystującą do wierceń najnowocześniejszy sprzęt oferowany na tym  rynku, tj.  wiertnicę na podwoziu gąsienicowym Beretta T-44.
        </Typography>
     
   
      </Grid>
   
      <Grid
         data-aos="fade-right"
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: '10px auto',
        }}
        order={{ xs: 3, md: 3 }}

      >
        <Typography
          component="h1"
          sx={{
            fontSize: isMobile ? "24px" : "32px",
            lineHeight: "48px",
            color: "#343434",
            padding: "10px 0",
            fontWeight: "bold",
            width: "100%",

            "&:after": {
              content: "''",
              margin: isMobile ? "5px 0" : "10px 0",
              display: "block",
              width: "15%",
              height: "3px",
              backgroundColor: `${theme.palette.secondary.main}`,
            },
          }}
        >
           Wiertnica Beretta T-44
        </Typography>
        <List dense   data-aos="fade-left">
              
                <ListItem sx={{fontFamily: "Montserrat-Regular"}}>
                  <ListItemIcon sx={{'&.MuiListItemIcon-root' : {
                      width: "35px",
                      minWidth: "unset"
                  }}}>
                    <CheckCircleOutlineOutlinedIcon />
                  </ListItemIcon>
                  <CustomListItem>Najnowocześniejszy model wiertnicy na podwoziu gąsienicowym Beretta T-44 – umożliwiający  dojazd w ciężko dostępne miejsca</CustomListItem>
                </ListItem>

                <ListItem>
                  <ListItemIcon sx={{'&.MuiListItemIcon-root' : {
                      width: "35px",
                      minWidth: "unset"
                  }}}>
                    <CheckCircleOutlineOutlinedIcon />
                  </ListItemIcon>
                  {/* <ListItemText
                    primary="Uniwersalne rozwiązania pozwalające na prowadzenie wierceń
                            rdzeniowych oraz mechanicznych bez i z orurowaniem"
                  /> */}
                    <CustomListItem>Uniwersalne rozwiązania pozwalające na prowadzenie wierceń
                            rdzeniowych oraz mechanicznych bez i z orurowaniem</CustomListItem>
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{'&.MuiListItemIcon-root' : {
                      width: "35px",
                      minWidth: "unset"
                  }}}>
                    <CheckCircleOutlineOutlinedIcon />
                  </ListItemIcon>
                  {/* <ListItemText
                    primary="Dwie wyciągarki, które usprawniają proces wiercenia"
                  /> */}
                   <CustomListItem>Dwie wyciągarki, które usprawniają proces wiercenia</CustomListItem>
                  
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{'&.MuiListItemIcon-root' : {
                      width: "35px",
                      minWidth: "unset"
                  }}}>
                    <CheckCircleOutlineOutlinedIcon />
                  </ListItemIcon>
                  {/* <ListItemText
                    primary="Aparat wrzutowy CSK-146 dzięki, któremu  można uzyskać próby
                        rdzenia 100 mm, zgodne z kategorią A według EUROKOD 7."
                  /> */}
                      <CustomListItem>Aparat wrzutowy CSK-146, dzięki któremu można uzyskać próby
                        rdzenia 100 mm, zgodne z kategorią A według EUROKOD 7</CustomListItem>
                </ListItem>
              
   
              
        </List>
   
      </Grid>
      <Grid
        data-aos="fade-right"
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent:isMobile ? "center" : "flex-end",
          alignItems: "center",
          margin: '10px auto',
        }}
        order={{ xs: 4, md: 4 }}
      >

      <Swiper
          slidesPerView={1}
          spaceBetween={50}
          pagination={{
            clickable: true,
          }}
          className="mySwiper"
          navigation
          centerInsufficientSlides
          centeredSlides
        
      >
        
          <SwiperSlide>
            <StaticImage
              src="../../assets/images/ggwiercenia_maszyna.jpg"
              alt="Wiertnica Beretta T-44"
              placeholder="blurred"
              layout="fixed"
              width={380}
       
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../../assets/images/ggwiercenia_beretta1.jpg"
              alt="Wiertnica Beretta T-44"
              placeholder="blurred"
              layout="fixed"
              width={380}
       
            />
          </SwiperSlide>
          <SwiperSlide>
          <StaticImage
              src="../../assets/images/ggwiercenia_beretta2.jpg"
              alt="Wiertnica Beretta T-44"
              placeholder="blurred"
              layout="fixed"
              width={380}
       
            />
          </SwiperSlide>

          <SwiperSlide>

      
            <StaticImage
              src="../../assets/images/ggwiercenia_beretta3.jpg"
              alt="Wiertnica Beretta T-44"
              placeholder="blurred"
              layout="fixed"
              width={380}
       
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../../assets/images/machine.jpg"
              alt="Wiertnica Beretta T-44"
              placeholder="blurred"
            
            />
          </SwiperSlide>
  
      </Swiper>

  

          {/* <StaticImage
            src="../../assets/images/machine.jpg"
            alt="Wiertnica Baretta T-44"
            placeholder="blurred"
            layout="constrained"
            width={400}
            style={ !isMobile ? { borderTopRightRadius:'20px', borderBottomRightRadius: '20px'} : {borderRadius: '20px'}}
          /> */}
        
      </Grid>

      
    

      <Grid
         data-aos="fade-left"
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent:isMobile ? "center" : "flex-start",
          alignItems: "center",
          margin: '10px auto'
        }}
        order={{ xs: 6, md: 5 }}
      >
      
          <StaticImage
            src="../../assets/images/ggwiercenia_wiercenia.jpg"
            alt="Wiercenia"
            placeholder="blurred"
            layout="constrained"
            objectFit="cover"
            width={400}
            height={500}
            style={ !isMobile ? { borderTopLeftRadius:'20px', borderBottomLeftRadius: '20px'} : {borderRadius: '20px'}}
          />

        
      </Grid>
      <Grid
         data-aos="fade-left"
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: '10px auto'
         
        }}
        order={{ xs: 5, md: 6 }}
      >
        <Typography
          component="h1"
          sx={{
            fontSize: isMobile ? "24px" : "32px",
            lineHeight: "48px",
            color: "#343434",
            padding: "10px 0",
            fontWeight: "bold",
            width: "100%",

            "&:after": {
              content: "''",
              margin: isMobile ? "5px 0" : "10px 0",
              display: "block",
              width: "15%",
              height: "3px",
              backgroundColor: `${theme.palette.secondary.main}`,
            },
          }}
        >
           Wiercenia
        </Typography>
        <List dense  data-aos="fade-left">
              
                <ListItem sx={{fontFamily: "Montserrat-Regular"}}>
                  <ListItemIcon sx={{'&.MuiListItemIcon-root' : {
                      width: "35px",
                      minWidth: "unset"
                  }}}>
                    <CheckCircleOutlineOutlinedIcon />
                  </ListItemIcon>
                  {/* <ListItemText
                    
                    primary="Rdzeniowe – przy użyciu aparatu wrzutowego CSK, co pozwala na uzyskanie próbek rdzenia 100mm - zgodnej z kategorią A wg EUROKOD 7"
                  /> */}

<CustomListItem>Rdzeniowe – przy użyciu aparatu wrzutowego CSK, co pozwala na uzyskanie próbek rdzenia 100mm - zgodnej z kategorią A wg EUROKOD 7</CustomListItem>
                </ListItem>

                <ListItem>
                  <ListItemIcon sx={{'&.MuiListItemIcon-root' : {
                      width: "35px",
                      minWidth: "unset"
                  }}}>
                    <CheckCircleOutlineOutlinedIcon />
                  </ListItemIcon>
                  {/* <ListItemText
                    primary="Mechaniczne bez rurowania (sznekowe) – przy użyciu świdrów ślimakowych, o średnicy fi=140mm"
                  /> */}
                  <CustomListItem>Mechaniczne bez rurowania (sznekowe) – przy użyciu świdrów ślimakowych, o średnicy fi=140mm</CustomListItem>

                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{'&.MuiListItemIcon-root' : {
                      width: "35px",
                      minWidth: "unset"
                  }}}>
                    <CheckCircleOutlineOutlinedIcon />
                  </ListItemIcon>
                  {/* <ListItemText
                    primary="Mechaniczne z rurowaniem – wykorzystując system ROTEX, o średnicy 168mm"
                  /> */}
                    <CustomListItem>Mechaniczne z rurowaniem – wykorzystując system ROTEX, o średnicy 168mm</CustomListItem>
                </ListItem>
        
        </List>
   
      </Grid>


      <Grid
       data-aos="fade-right"
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: '10px auto'
        }}
        order={{ xs: 7, md: 7 }}
      >
        <Typography
          component="h1"
          sx={{
            fontSize: isMobile ? "24px" : "32px",
            lineHeight: "48px",
            color: "#343434",
            padding: "10px 0",
            fontWeight: "bold",
            width: "100%",

            "&:after": {
              content: "''",
              margin: isMobile ? "5px 0" : "10px 0",
              display: "block",
              width: "15%",
              height: "3px",
              backgroundColor: `${theme.palette.secondary.main}`,
            },
          }}
        >
           Sondowanie
        </Typography>
        <List dense  data-aos="fade-left">
              
                <ListItem sx={{fontFamily: "Montserrat-Regular"}}>
                  <ListItemIcon sx={{'&.MuiListItemIcon-root' : {
                      width: "35px",
                      minWidth: "unset"
                  }}}>
                    <CheckCircleOutlineOutlinedIcon />
                  </ListItemIcon>
                  <CustomListItem>Dynamiczne – DPL / DPM / DPH</CustomListItem>
                </ListItem>

                <ListItem>
                  <ListItemIcon sx={{'&.MuiListItemIcon-root' : {
                      width: "35px",
                      minWidth: "unset"
                  }}}>
                    <CheckCircleOutlineOutlinedIcon />
                  </ListItemIcon>
                 
                     <CustomListItem>Statyczne – CPT, CPTu</CustomListItem>
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{'&.MuiListItemIcon-root' : {
                      width: "35px",
                      minWidth: "unset"
                  }}}>
                    <CheckCircleOutlineOutlinedIcon />
                  </ListItemIcon>
                 
                  <CustomListItem>Badania płytą statyczną VSS</CustomListItem>
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{'&.MuiListItemIcon-root' : {
                      width: "35px",
                      minWidth: "unset"
                  }}}>
                    <CheckCircleOutlineOutlinedIcon />
                  </ListItemIcon>
               
                   <CustomListItem>Badania płytą dynamiczną LPD</CustomListItem>
                </ListItem>
        
        </List>
   
      </Grid>
      <Grid
            data-aos="fade-right"
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent:isMobile ? "center" : "flex-end",
          alignItems: "center",
          margin: '10px auto'
        }}
        order={{ xs: 8, md: 8 }}
      >
      
          <StaticImage
           src="../../assets/images/ggwiercenia_sondowanie.jpg"
            alt="Sondowanie"
            placeholder="blurred"
            layout="constrained"
            width={400}
            style={ !isMobile ? { borderTopRightRadius:'20px', borderBottomRightRadius: '20px'} : {borderRadius: '20px'}}
          />
        
      </Grid>


      <Grid
       data-aos="fade-left"
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: '10px auto'
        }}
        order={{ xs: 9, md: 10 }}
      >
        <Typography
          component="h1"
          sx={{
            fontSize: isMobile ? "24px" : "32px",
            lineHeight: "48px",
            color: "#343434",
            padding: "10px 0",
            fontWeight: "bold",
            width: "100%",
        

            "&:after": {
              content: "''",
              margin: isMobile ? "5px 0" : "10px 0",
              display: "block",
              width: "15%",
              height: "3px",
              backgroundColor: `${theme.palette.secondary.main}`,
            },
          }}
        >
           Monitoring geotechniczny i wiercenia specjalistyczne
        </Typography>
        <List dense  data-aos="fade-left">
              
                <ListItem sx={{fontFamily: "Montserrat-Regular"}}>
                  <ListItemIcon sx={{'&.MuiListItemIcon-root' : {
                      width: "35px",
                      minWidth: "unset"
                  }}}>
                    <CheckCircleOutlineOutlinedIcon />
                  </ListItemIcon>
                 
                  <CustomListItem>Montaż i monitoring piezometrów</CustomListItem>
                </ListItem>

                <ListItem>
                  <ListItemIcon sx={{'&.MuiListItemIcon-root' : {
                      width: "35px",
                      minWidth: "unset"
                  }}}>
                    <CheckCircleOutlineOutlinedIcon />
                  </ListItemIcon>
                
                      <CustomListItem>Montaż i monitoring inklinometrów</CustomListItem>

                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{'&.MuiListItemIcon-root' : {
                      width: "35px",
                      minWidth: "unset"
                  }}}>
                    <CheckCircleOutlineOutlinedIcon />
                  </ListItemIcon>
                  
                    <CustomListItem>Wiercenie mikropali</CustomListItem>
                </ListItem>
          
        
        </List>
   
      </Grid>
      <Grid
        data-aos="fade-left"
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent:isMobile ? "center" : "flex-start",
          alignItems: "center",
          margin: '10px auto'
        }}
        order={{ xs: 10, md: 9 }}
      >
      
       
      <StaticImage
           src="../../assets/images/ggwiercenia_monitoring.jpg"
            alt="Sondowanie"
            placeholder="blurred"
            layout="constrained"
            width={400}
            style={ !isMobile ? { borderTopRightRadius:'20px', borderBottomRightRadius: '20px'} : {borderRadius: '20px'}}
          />
        
      </Grid>
     


      <Grid
       data-aos="fade-right"
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: '10px auto'
        }}
        order={{ xs: 11, md: 11 }}
      >
        <Typography
          component="h1"
          sx={{
            fontSize: isMobile ? "24px" : "32px",
            lineHeight: "48px",
            color: "#343434",
            padding: "10px 0",
            fontWeight: "bold",
            width: "100%",

            "&:after": {
              content: "''",
              margin: isMobile ? "5px 0" : "10px 0",
              display: "block",
              width: "15%",
              height: "3px",
              backgroundColor: `${theme.palette.secondary.main}`,
            },
          }}
        >
          Dokumentacje
        </Typography>
        <List dense  data-aos="fade-left">
              
                <ListItem sx={{fontFamily: "Montserrat-Regular"}}>
                  <ListItemIcon sx={{'&.MuiListItemIcon-root' : {
                      width: "35px",
                      minWidth: "unset"
                  }}}>
                    <CheckCircleOutlineOutlinedIcon />
                  </ListItemIcon>
                  
                      
                  <CustomListItem>Projekt robót geologicznych</CustomListItem>
                </ListItem>

                <ListItem>
                  <ListItemIcon sx={{'&.MuiListItemIcon-root' : {
                      width: "35px",
                      minWidth: "unset"
                  }}}>
                    <CheckCircleOutlineOutlinedIcon />
                  </ListItemIcon>
                  
                          
                  <CustomListItem>Dokumentacja geologiczno-inżynierska</CustomListItem>
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{'&.MuiListItemIcon-root' : {
                      width: "35px",
                      minWidth: "unset"
                  }}}>
                    <CheckCircleOutlineOutlinedIcon />
                  </ListItemIcon>
                  
                               
                    <CustomListItem>Dokumentacja geotechniczna</CustomListItem>
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{'&.MuiListItemIcon-root' : {
                      width: "35px",
                      minWidth: "unset"
                  }}}>
                    <CheckCircleOutlineOutlinedIcon />
                  </ListItemIcon>
                 
                   <CustomListItem>Dokumentacja hydrogeologiczna</CustomListItem>
                </ListItem>
          
        
        </List>
   
      </Grid>
      <Grid
        data-aos="fade-right"
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent:isMobile ? "center" : "flex-end",
          alignItems: "center",
          margin: '10px auto'
        }}
        order={{ xs: 12, md: 12 }}
      >
          <StaticImage
            src="../../assets/images/offer-docs.jpg"
            alt="Dokumentacja"
            placeholder="blurred"
            layout="constrained"
            width={400}
            style={ !isMobile ? { borderTopRightRadius:'20px', borderBottomRightRadius: '20px'} : {borderRadius: '20px'}}
          />
        
      </Grid>

 
      <Grid
        data-aos="fade-left"
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent:isMobile ? "center" : "flex-start",
          alignItems: "center",
          margin: '10px auto'
        }}
        order={{ xs: 14, md: 13 }}
      >
      
          <StaticImage
            src="../../assets/images/offer-others.jpg"
            alt="Inne"
            placeholder="blurred"
            layout="constrained"
            width={400}
            style={ !isMobile ? { borderTopLeftRadius:'20px', borderBottomLeftRadius: '20px'} : {borderRadius: '20px'}}
          />
        
      </Grid>
      <Grid
        data-aos="fade-left"
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: '10px auto'
        }}
        order={{ xs: 13, md: 14 }}
      >
        <Typography
          component="h1"
          sx={{
            fontSize: isMobile ? "24px" : "32px",
            lineHeight: "48px",
            color: "#343434",
            padding: "10px 0",
            fontWeight: "bold",
            width: "100%",

            "&:after": {
              content: "''",
              margin: isMobile ? "5px 0" : "10px 0",
              display: "block",
              width: "15%",
              height: "3px",
              backgroundColor: `${theme.palette.secondary.main}`,
            },
          }}
        >
          Inne
        </Typography>
        <List dense  data-aos="flip-up"   data-aos-duration="5000">
              
                <ListItem sx={{fontFamily: "Montserrat-Regular"}}>
                  <ListItemIcon sx={{'&.MuiListItemIcon-root' : {
                      width: "35px",
                      minWidth: "unset"
                  }}}>
                    <CheckCircleOutlineOutlinedIcon />
                  </ListItemIcon>
                 
                  <CustomListItem>Dozór oraz kierowanie robotami i pracami geologicznymi</CustomListItem>
                </ListItem>

                <ListItem>
                  <ListItemIcon sx={{'&.MuiListItemIcon-root' : {
                      width: "35px",
                      minWidth: "unset"
                  }}}>
                    <CheckCircleOutlineOutlinedIcon />
                  </ListItemIcon>
                 
                      <CustomListItem>Pobór prób gruntów oraz wód</CustomListItem>
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{'&.MuiListItemIcon-root' : {
                      width: "35px",
                      minWidth: "unset"
                  }}}>
                    <CheckCircleOutlineOutlinedIcon />
                  </ListItemIcon>
                 

                  <CustomListItem>Kompleksowe badania laboratoryjne</CustomListItem>
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{'&.MuiListItemIcon-root' : {
                      width: "35px",
                      minWidth: "unset"
                  }}}>
                    <CheckCircleOutlineOutlinedIcon />
                  </ListItemIcon>
                 
                  
                  <CustomListItem>Obsługa geodezyjna</CustomListItem>
                </ListItem>
          
        
        </List>
   
      </Grid>

   
    </Grid>
       {showSlider && (
        <Swiper
          slidesPerView={isMobile ? 1 : 3}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          className="mySwiper"
          navigation
        >
          <SwiperSlide>
            <StaticImage
              src="../../assets/images/drill-1.jpg"
              alt=""
              placeholder="blurred"
              layout="constrained"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../../assets/images/drill-2.jpg"
              alt=""
              placeholder="blurred"
              layout="constrained"
            />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <StaticImage
              src="../../assets/images/drill-3.jpg"
              alt=""
              placeholder="blurred"
              layout="constrained"
            />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <StaticImage
              src="../../assets/images/drill-1.jpg"
              alt=""
              placeholder="blurred"
              layout="constrained"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../../assets/images/drill-1.jpg"
              alt=""
              placeholder="blurred"
              layout="constrained"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../../assets/images/drill-3.jpg"
              alt=""
              placeholder="blurred"
              layout="constrained"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../../assets/images/drill-2.jpg"
              alt=""
              placeholder="blurred"
              layout="constrained"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../../assets/images/drill-1.jpg"
              alt=""
              placeholder="blurred"
              layout="constrained"
            />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <StaticImage
              src="../../assets/images/drill-3.jpg"
              alt=""
              placeholder="blurred"
              layout="constrained"
            />
          </SwiperSlide>
        </Swiper>
      )}
      </>
  )
}

export default Offer

